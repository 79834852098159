/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useState } from 'react';

import { useNavigate, useLocation } from 'react-router-dom';

import { t } from 'i18next';

import { Button, SearchBar, Store, Text, View } from '@az/starc-ui';

import { MasterTitle } from '@shared/components/MasterTitle/MasterTitle';
import { PAGE_URLS, ROUTES } from '@shared/constants/routes';

import { KEY, KEY_CODE } from '@shared/constants/keyConstants';
import styles from './ControlDesk.module.scss';
import { useAtom } from 'jotai';
import { useStoreSearch } from '@ofm/services/useStoreSearch';
import { EmptyState } from '@shared/components/EmptyState/EmptyState';
import { EmptySuggestions } from '@shared/components/EmptySuggestions/EmptySuggestions';
import {
  inLaneConfirmationAtom,
  laneConfirmationItemAtom,
  replenishedAtom,
  selectedOrdersAtom,
  selectedSubzonesAtom,
} from '@outbound/atoms/releaseOrder/releaseOrderAtom';

import { ConfirmationModal } from '@outbound/components/Modal/ConfirmationModal';
import { OrderTabs } from './OrderTabs';
import { Overview } from './Overview';
import { ClosingPaperModal } from './ReleaseOrders/ReleaseToPaper/ClosingPaperModal';
import { closingPaperAtom } from '@outbound/atoms/releaseToPaper/releaseToPaperAtom';

export const ControlDesk = () => {
  /* Atoms */
  const [selectedOrders] = useAtom(selectedOrdersAtom);
  const [selectedSubzones] = useAtom(selectedSubzonesAtom);
  const [inLaneConfirmation] = useAtom(inLaneConfirmationAtom);
  const [replenishedStores] = useAtom(replenishedAtom);
  const [selectedItem] = useAtom(laneConfirmationItemAtom);
  const [closingPaper] = useAtom(closingPaperAtom);

  /* State variables */
  const [storeOrderSearch, setStoreOrderSearch] = useState('');
  const [showLaneConfirmation, setShowLaneConfirmation] = useState(false);

  /* Constants */
  const navigate = useNavigate();
  const location = useLocation();
  const isOrderReleasePage = location.pathname.includes(ROUTES.ORDER_RELEASE);
  const isNewStoreTab = location.pathname.includes(ROUTES.New_Store);

  const pageTitle = isOrderReleasePage
    ? inLaneConfirmation
      ? 'OutboundMatrix.OrderRelease.LaneConfirmation'
      : 'OutboundMatrix.OrderRelease.Title'
    : 'MasterTitle.OutboundControlDesk';

  /* Queries */
  const { stores, hasNoResults, invalidId } = useStoreSearch({
    storeId: storeOrderSearch,
    currentPage: 0,
    pageSize: 10,
  });

  /* Functions */
  const setSearchParam = (param: string) => {
    stores.includes(param) && navigate(PAGE_URLS.STORE_ORDER_DETAILS(param));
  };

  const onKeyDown = (e: {
    key: string;
    keyCode: number;
    preventDefault: () => void;
    currentTarget: { value: string };
  }) => {
    if (e.key === KEY.ENTER || e.keyCode === KEY_CODE.ENTER) {
      e.preventDefault();
      const inputValue = (e.currentTarget as HTMLInputElement).value;
      if (inputValue) {
        setSearchParam(inputValue);
      }
    }
  };
  const onSelectionChange = (value: string) => {
    if (value !== undefined) {
      setSearchParam(value);
    }
  };

  /* Functions */
  const getOrderReleasePathName = () => {
    const tab = location.pathname.split('/').at(-1);
    return tab ? location.pathname.replace(tab, `${ROUTES.ORDER_RELEASE_DIF}`) : location.pathname;
  };

  const onProceedToLaneConfirmation = () => {
    navigate(PAGE_URLS.LANE_CONFIRMATION_ORDER_RELEASE);
  };

  const onNavigateToControlDesk = () => {
    const selectedTabRoute = location.pathname.split('/').at(-1);
    navigate(`${PAGE_URLS.OUTBOUND_CONTROL_DESK}/${selectedTabRoute}`);
  };

  return (
    <View direction="column" height="100%">
      <MasterTitle
        title={t(pageTitle)}
        // TODO: Add functionality to save page to favorites column
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        titleActionProps={{ label: 'Favorite', handleClick: () => {} }}
        subtitle={
          <View direction="row" gap={4}>
            <Text>
              {t('OutboundMatrix.OrderRelease.ShiftGoalOrdersReleased', { count: 15, max: 80 })}
            </Text>
          </View>
        }
        {...(isOrderReleasePage
          ? {
              breadcrumbProps: {
                data: [
                  {
                    label: t('Sidenav.OutboundControlDesk'),
                    onClick: onNavigateToControlDesk,
                  },
                  { label: t('OutboundMatrix.OrderRelease.Title'), onClick: () => void 0 },
                ],
              },
            }
          : {})}
      >
        <View direction="row" justify="end" align="center" gap={4}>
          <View.Item columns={4}>
            <SearchBar
              value={storeOrderSearch}
              className={styles['search-bar']}
              onValueChange={setStoreOrderSearch}
              suggestions={hasNoResults ? <EmptySuggestions /> : stores}
              label={t('Search.DCNumber')}
              onSelectionChange={onSelectionChange}
              inputAttributes={{ onKeyDown: onKeyDown }}
              maxMenuHeight={300}
            />
          </View.Item>
          <View.Item>
            {isOrderReleasePage ? (
              <Button
                size="large"
                variant="primary"
                disabled={!selectedOrders.length && !selectedSubzones?.length}
                onClick={onProceedToLaneConfirmation}
              >
                <View direction="row" align="center" justify="center" gap={2}>
                  <Text>{t('OutboundMatrix.OrderRelease.ProceedToLaneConfirmation')}</Text>
                </View>
              </Button>
            ) : (
              <Button
                size="large"
                variant="primary"
                onClick={() => navigate(getOrderReleasePathName())}
              >
                <View direction="row" align="center" justify="center" gap={2}>
                  <Text>{t('MasterTitle.ReleaseOrders')}</Text>
                </View>
              </Button>
            )}
          </View.Item>
        </View>
      </MasterTitle>
      <View className={styles['outbound-matrix-tabs-wrapper']}>
        {hasNoResults ? (
          <View height="100%">
            <EmptyState
              svg={Store}
              subtitle={t('Empty.Search.Subtitle')}
              text={t('Empty.Search.Text', {
                value: invalidId,
              })}
            />
          </View>
        ) : (
          <View direction="column" height="100%" width="100%">
            <OrderTabs />
          </View>
        )}

        {!isOrderReleasePage && <Overview />}
        {showLaneConfirmation && (
          <ConfirmationModal
            title={t('ConfirmationComment.LaneConfirmation')}
            isCancelBtn={true}
            open={showLaneConfirmation}
            onClose={() => setShowLaneConfirmation(false)}
            onSuccess={() => {
              setShowLaneConfirmation(false);
              navigate(PAGE_URLS.LANE_CONFIRMATION_ORDER_RELEASE);
            }}
            primaryBtnText={t('ConfirmationComment.YesProceed')}
          >
            <View wrap={true}>
              {isNewStoreTab ? (
                <Text className={styles['confirmation-text']}>
                  {selectedItem &&
                  selectedSubzones.length > 0 &&
                  selectedSubzones.length === selectedItem?.subzones?.length
                    ? t('ConfirmationComment.LaneConfirmationNewStoreSubtitle', {
                        id: selectedItem.id,
                      })
                    : t('ConfirmationComment.LaneConfirmationSubzoneSubtitle', {
                        id: selectedSubzones.filter(
                          (subzone) => !replenishedStores.includes(subzone)
                        ),
                      })}
                </Text>
              ) : (
                <Text className={styles['confirmation-text']}>
                  {selectedItem &&
                  selectedOrders.length > 0 &&
                  selectedOrders.length === selectedItem?.stores?.length
                    ? t('ConfirmationComment.LaneConfirmationSubtitle', { id: selectedItem.id })
                    : t('ConfirmationComment.LaneConfirmationOrdersSubtitle', {
                        id: selectedOrders.filter((store) => !replenishedStores.includes(store)),
                      })}
                </Text>
              )}
            </View>
          </ConfirmationModal>
        )}
        {closingPaper && <ClosingPaperModal />}
      </View>
    </View>
  );
};
