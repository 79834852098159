/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import dayjs, { Dayjs } from 'dayjs';

import { DateFilters } from '@inventory/constants/constants';
import type { FiscalCalendarInfo } from '@inventory/types/types';
import { t } from 'i18next';

type DateRange = {
  fromTs: string;
  toTs: string;
};

const SUNDAY = 0;

// Helper function to find the previous Sunday from a given date
const getPreviousSunday = (date: dayjs.Dayjs) => {
  const dayOfWeek = date.day();
  return dayOfWeek === SUNDAY ? date : date.subtract(dayOfWeek, 'day');
};

const getStartOfFiscalYear = (referenceDate: dayjs.Dayjs) => {
  let fiscalYearStart = dayjs(`${referenceDate.year()}-09-01`);
  fiscalYearStart = getPreviousSunday(fiscalYearStart);

  if (referenceDate.isBefore(fiscalYearStart)) {
    fiscalYearStart = dayjs(`${referenceDate.year() - 1}-09-01`);
    fiscalYearStart = getPreviousSunday(fiscalYearStart);
  }

  return fiscalYearStart;
};

/**
 * Represents a date range with start and end timestamps.
 * @typedef {Object} DateRange
 * @property {string} fromTs - The start timestamp in ISO string format.
 * @property {string} toTs - The end timestamp in ISO string format.
 */

/**
 * Maps a date filter to a corresponding date range based on the reference date.
 *
 * @param {string} filter - The filter to apply for the date range.
 * Possible values are:
 * - 'ThisWeek': Returns the current week range (Sunday to Saturday).
 * - 'Last4Weeks': Returns the range of the last 4 weeks.
 * - 'FiscalYear': Returns the fiscal year range based on the reference date.
 * @param {Dayjs} [referenceDate=dayjs()] - The reference date to calculate the range from. Defaults to the current date.
 * @returns {DateRange} The calculated date range with fromTs and toTs as ISO strings.
 *
 * @example
 * // Example usage:
 * const today = dayjs('2024-10-22'); // Example reference date
 *
 * const thisWeekRange = mapDateFilterToRange('ThisWeek', today);
 * Output: { fromTs: '2024-10-20T00:00:00.000Z', toTs: '2024-10-26T23:59:59.999Z' }
 *
 * const last4WeeksRange = mapDateFilterToRange('Last4Weeks', today);
 * Output: { fromTs: '2024-09-29T00:00:00.000Z', toTs: '2024-10-19T23:59:59.999Z' }
 *
 * const fiscalYearRange = mapDateFilterToRange('FiscalYear', today);
 *  Output: { fromTs: '2024-09-01T00:00:00.000Z', toTs: '2025-08-31T23:59:59.999Z' }
 */
export const mapDateFilterToRange = (filter: string, referenceDate: Dayjs = dayjs()): DateRange => {
  let fromTs: Dayjs;
  let toTs: Dayjs;

  switch (filter) {
    case DateFilters.THIS_WEEK:
      fromTs = referenceDate.startOf('week'); // Start of week (Sunday)
      toTs = fromTs.add(6, 'days').endOf('day'); // End of week (Saturday)
      break;

    case DateFilters.LAST_4_WEEKS: {
      // Start from the Sunday 4 weeks ago to the last Saturday
      fromTs = referenceDate.subtract(4, 'weeks').startOf('week'); // Start of the week 4 weeks ago (Sunday)
      toTs = referenceDate.startOf('week').subtract(1, 'day').endOf('day'); // End of last week (Saturday)
      break;
    }

    case DateFilters.LAST_WEEK: {
      // Start from the previous Sunday to the last Saturday
      fromTs = referenceDate.subtract(1, 'week').startOf('week'); // Start of last week (Sunday)
      toTs = referenceDate.startOf('week').subtract(1, 'day').endOf('day'); // End of last week (Saturday)
      break;
    }

    case DateFilters.CURRENT_PERIOD: {
      const periodStart = getStartOfFiscalYear(referenceDate); // Start of the current fiscal period
      fromTs = periodStart; // Start of the fiscal period or current period
      toTs = referenceDate.endOf('day'); // Today's end as the current period end
      break;
    }

    case DateFilters.FISCAL_YEAR: {
      // First Sunday on or before September 1 of the current year
      const fiscalYearStart = getStartOfFiscalYear(referenceDate); // First Sunday on/before Sept 1

      // Add 380 days to start of the fiscal year and get next fiscal year start date
      let endOfFiscalYear = getStartOfFiscalYear(fiscalYearStart.add(380, 'day'));
      endOfFiscalYear = endOfFiscalYear.subtract(1, 'day'); // Subtract 1 day for end date

      fromTs = fiscalYearStart;
      toTs = endOfFiscalYear;
      break;
    }

    default:
      // Default to "This Week" if no filter is selected
      fromTs = referenceDate.startOf('week'); // Start of week (Sunday)
      toTs = fromTs.add(6, 'days').endOf('day'); // Saturday
      break;
  }

  return {
    fromTs: fromTs.toISOString(),
    toTs: toTs.toISOString(),
  };
};

export const formattedDate = (date: string | null | undefined, format = 'DD/MM/YYYY') =>
  date ? dayjs(date).format(format) : 'N/A';

/**
 * Calculates the date ranges for "Last Week" and "Last 4 Weeks" using fiscal calendar information.
 */
export const getLastWeekAndLast4WeeksFromFiscalInfo = (fiscalCalendarInfo: FiscalCalendarInfo) => {
  if (!fiscalCalendarInfo || !fiscalCalendarInfo.week) {
    return null;
  }

  // Start of this week (Sunday)
  const thisWeekStart = dayjs(fiscalCalendarInfo.week.startDate);

  // Last Week
  const lastWeekStart = thisWeekStart.subtract(1, 'week');
  const lastWeekEnd = thisWeekStart.subtract(1, 'day').endOf('day');

  // Last 4 Weeks
  const last4WeeksStart = thisWeekStart.subtract(4, 'weeks');

  return {
    lastWeek: {
      fromTs: lastWeekStart.toISOString(),
      toTs: lastWeekEnd.toISOString(),
    },
    last4Weeks: {
      fromTs: last4WeeksStart.toISOString(),
      toTs: lastWeekEnd.toISOString(),
    },
  };
};

/**
 * Retrieves the date range for a specific filter using fiscal calendar information.
 * @param filter - The date filter type (e.g., "ThisWeek", "LastWeek", etc.).
 * @param fiscalCalendarInfo - The fiscal calendar information containing week, period, and year details.
 * @returns The date range for the specified filter or null if the filter is not valid.
 */
export const getRangeFromFiscalInfo = (
  filter: string,
  fiscalCalendarInfo: FiscalCalendarInfo | null
): { fromTs: string; toTs: string } | null => {
  if (!fiscalCalendarInfo) return null;

  const lastNWeekInfo = getLastWeekAndLast4WeeksFromFiscalInfo(fiscalCalendarInfo);

  switch (filter) {
    case DateFilters.THIS_WEEK:
      return fiscalCalendarInfo.week
        ? {
            fromTs: dayjs(fiscalCalendarInfo.week.startDate).toISOString(),
            toTs: dayjs(fiscalCalendarInfo.week.endDate).toISOString(),
          }
        : null;
    case DateFilters.LAST_WEEK:
      return lastNWeekInfo?.lastWeek || null;
    case DateFilters.LAST_4_WEEKS:
      return lastNWeekInfo?.last4Weeks || null;
    case DateFilters.CURRENT_PERIOD:
      return fiscalCalendarInfo.period
        ? {
            fromTs: dayjs(fiscalCalendarInfo.period.startDate).toISOString(),
            toTs: dayjs(fiscalCalendarInfo.period.endDate).toISOString(),
          }
        : null;
    case DateFilters.FISCAL_YEAR:
      return fiscalCalendarInfo.year
        ? {
            fromTs: dayjs(fiscalCalendarInfo.year.startDate).toISOString(),
            toTs: dayjs(fiscalCalendarInfo.year.endDate).toISOString(),
          }
        : null;
    default:
      return null;
  }
};

/**
 * Returns a valid date as a formatted string based on the provided input date.
 *
 * - If the input date is `null`, `undefined`, or invalid, the current date is returned.
 * - If the input date is in the future, the current date is returned.
 * - Otherwise, the input date is returned.
 *
 * @param {string | null | undefined} endDate - The input date to validate.
 * @param {string} [format='MM/DD/YYYY'] - The format for the output date string (default is 'MM/DD/YYYY').
 * @returns {string} The formatted date string, either the current date or the input date.
 */
export const getValidDate = (endDate: string | null | undefined, format = 'MM/DD/YYYY'): string => {
  const currentDate = dayjs(); // Get the current date

  // Check if the input date is valid
  if (!endDate || !dayjs(endDate).isValid()) {
    return currentDate.format(format); // Return current date if input is invalid
  }

  const inputDate = dayjs(endDate);

  // Return current date if the input date is in the future; otherwise, return the input date
  return inputDate.isAfter(currentDate) ? currentDate.format(format) : inputDate.format(format);
};

// Helper function to generate base label for column
export const getVarianceBaseLabel = (type: string) => {
  if (type?.includes('SKU')) {
    return 'TopVariances.SKU';
  } else if (type?.includes('SUBZONE')) {
    return 'TopVariances.Subzone';
  } else if (type?.includes('PRODUCT_CATEGORY')) {
    return 'TopVariances.ProductCategory.Title';
  } else if (type?.includes('VENDOR')) {
    return 'TopVariances.Vendor';
  }
  return '';
};

// Helper function to generate modal title
export const getVarianceModalTitle = (type: string) => {
  const baseLabel = t(getVarianceBaseLabel(type));
  return baseLabel ? t('TopVariances.ProductCategory.ModalTitle', { label: baseLabel }) : '';
};
