/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useEffect, useState } from 'react';
import { View, Text, Button, Icon, Actionable, Dropdown, Divider, Checkbox } from '@az/starc-ui';
import { statusToBadgeVariant } from '@ofm/utils/utils';
import { Download } from '@shared/assets/icons';
import { MasterTitle } from '@shared/components/MasterTitle/MasterTitle';
import { PAGE_URLS, ROUTES } from '@shared/constants/routes';
import { useDownload } from '@shared/hooks/useDownload';
import { t } from 'i18next';
import { generateDateString } from '@shared/utils/commonUtils';
import { ActionDropdownMenu } from '@shared/components/ActionDropdownMenu/ActionDropdownMenu';
import { EndCountModal } from '../EndCountModal/EndCountModal';
import { DownloadFileType } from '@inbound/types/types';
import { DEFAULT_PAGE, DESC, FILE_TYPES } from '@inbound/constants/constants';
import styles from './CycleCountDetailsHeader.module.scss';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDownloadSubzones } from '@inventory/services/hooks/useCycleCounts';
import {
  CYCLE_COUNT_ENTITY_ASSOCIATION,
  CYCLE_COUNT_STATUS_CD,
} from '@inventory/constants/constants';
import { PAGE_SIZE, SORT_BY } from '@shared/constants/constants';
import { CycleCountDetailsHeaderProps } from './CycleCountDetailsHeader.types';
import { checkCountTypeCd } from '@inventory/utils/utils';
import { getHeaderDetails } from '@inventory/utils/cycleCountHeaderUtil';

export const CycleCountDetailsHeader = ({
  progressValue,
  taskId,
  year,
  countTypeCd,
  phase,
  week,
  statusCd,
  startTs,
}: CycleCountDetailsHeaderProps) => {
  const navigate = useNavigate();
  const selectedTab = useLocation().pathname.split('/').at(-1);
  const [headerData, setHeaderData] = useState<CycleCountDetailsHeaderProps[]>();
  const isOverViewTab = selectedTab === ROUTES.OVERVIEW;
  const isCycleCountComplete = statusCd === CYCLE_COUNT_STATUS_CD.COMPLETE;
  const { downloadContentAsPDF, isDownloading } = useDownload();
  const rootPath = PAGE_URLS.CYCLE_COUNTS_DETAILS(taskId ?? '', countTypeCd);
  const countTypeCdAPI = checkCountTypeCd(countTypeCd);
  const [isModalOpen, setModalOpen] = useState(false);
  const [downloadWithFilters, setDownloadWithFilters] = useState<boolean>(true);
  const [openExportDropdown, setOpenExportDropdown] = useState<boolean>(false);
  const [isExportInProgress, setIsExportInProgress] = useState<boolean>(false);
  const [exportType, setExportType] = useState<DownloadFileType>('');
  const [headerTitle, setHeaderTitle] = useState<string>();

  useEffect(() => {
    const headerComponentData: CycleCountDetailsHeaderProps[] = [];
    headerComponentData.push({
      year: year,
      countTypeCd: countTypeCdAPI,
      phase: phase,
      week: week || 0,
      statusCd: statusCd || '',
      startTs: startTs || '',
    });
    if (Array.isArray(headerComponentData)) {
      const { headerTitle } = getHeaderDetails(headerComponentData || []);
      setHeaderTitle(headerTitle);
      setHeaderData(headerComponentData);
    }
  }, [countTypeCdAPI, phase, startTs, statusCd, taskId, week, year]);

  //Queries
  const { refetch: downloadFile } = useDownloadSubzones({
    searchPage: {
      page: DEFAULT_PAGE,
      size: PAGE_SIZE,
      sort: [{ sortBy: SORT_BY.YEAR, direction: DESC }],
    },
    searchCriteria: {
      countRequest: {
        countTypeCd: countTypeCdAPI,
        taskId: taskId,
        statusCd: [statusCd],
      },
      entitiyAssociations: [
        CYCLE_COUNT_ENTITY_ASSOCIATION.COUNT_LAYOUT_SUBZONE,
        CYCLE_COUNT_ENTITY_ASSOCIATION.COUNT_LAYOUT_LOCATION,
        CYCLE_COUNT_ENTITY_ASSOCIATION.COUNT_PERFORM_ACTION,
      ],
    },
    type: exportType === FILE_TYPES.PDF ? 'application/pdf' : 'application/csv',
  });

  const breadcrumbs = {
    data: [
      {
        label: t('CycleCount.Title'),
        onClick: () => navigate(PAGE_URLS.CYCLE_COUNTS),
      },
      {
        label: t('CycleCount.countTypeBreadcrumbs', {
          dynamicBreadcrumbs: checkCountTypeCd(countTypeCdAPI),
        }),
        onClick: () => navigate(''),
      },
    ],
  };

  const date: Date | undefined = startTs ? new Date(startTs) : undefined;
  const formattedDate = date ? generateDateString(date, 'DD/MM/YYYY HH:mm:ss') : '';

  const toggleExportDropDown = () => setOpenExportDropdown(!openExportDropdown);
  const toggleDownloadFilter = () => setDownloadWithFilters(!downloadWithFilters);

  const handleDownload = (type: DownloadFileType) => {
    setIsExportInProgress(true);
    setExportType(type);
    toggleExportDropDown();
  };

  useEffect(() => {
    if (exportType !== '') {
      downloadFile()
        .then(() => {
          setIsExportInProgress(false);
          setExportType('');
          return;
        })
        .catch((_) => {
          setExportType('');
          setIsExportInProgress(false);
        });
    }
  }, [downloadFile, exportType]);

  return (
    <>
      <MasterTitle
        breadcrumbProps={breadcrumbs}
        title={headerTitle}
        {...(statusCd && {
          statusBadgeProps: {
            variant: statusToBadgeVariant(statusCd ?? ''),
            text: statusCd ?? '',
          },
        })}
        titleActionProps={{
          label: 'Favorite',
          handleClick: () => {
            return;
          },
        }}
        subtitle={
          <View direction="row" gap={4}>
            <View.Item>
              <Text color="600">{`${t('CycleCount.Started', { date: formattedDate })}`}</Text>
            </View.Item>
          </View>
        }
      >
        <View direction="row" justify="end" align="center" gap={4}>
          <View.Item>
            <Dropdown
              className={styles['cycle-count-details-header__download_content']}
              padding={2}
              variant="ghost"
              width={300}
            >
              <Dropdown.Button
                className={styles['cycle-count-details-header__download_content__button']}
                onClick={toggleExportDropDown}
                disabled={isExportInProgress}
              >
                {isExportInProgress || isDownloading ? (
                  <Button loading={true} variant="ghost" />
                ) : (
                  <View direction="row" align="center" justify="center" gap={2}>
                    <Icon svg={Download} />
                    <Text size="100" weight="bold">
                      {t('InboundDashboard.Export.Title')}
                    </Text>
                  </View>
                )}
              </Dropdown.Button>
              <Dropdown.Content>
                <View gap={2} padding={3}>
                  {!isOverViewTab && (
                    <View
                      direction="row"
                      align="center"
                      gap={2}
                      className={
                        styles['cycle-count-details-header__download_content__button-details']
                      }
                      as="div"
                      attributes={{
                        onClick: () => handleDownload(FILE_TYPES.CSV),
                      }}
                    >
                      <Icon svg={Download} />
                      <Text size="075" weight="bold">
                        {t('InboundDashboard.Export.CSV')}
                      </Text>
                    </View>
                  )}
                  <View
                    direction="row"
                    align="center"
                    gap={2}
                    className={
                      styles['cycle-count-details-header__download_content__button-details']
                    }
                    as="div"
                    attributes={{
                      onClick: () =>
                        isOverViewTab
                          ? downloadContentAsPDF('OverView')
                          : handleDownload(FILE_TYPES.PDF),
                    }}
                  >
                    <Icon svg={Download} />
                    <Text size="075" weight="bold">
                      {t('InboundDashboard.Export.PDF')}
                    </Text>
                  </View>
                  {!isOverViewTab && (
                    <>
                      <View>
                        <Divider blank color="300" />
                      </View>
                      <View direction="row" gap={2} align="center">
                        <Checkbox
                          checked={downloadWithFilters}
                          label={t('InboundDashboard.Export.FilterTitle')}
                          value="Exportfilters"
                          name="Exportfilters"
                          onChange={() => toggleDownloadFilter()}
                        />
                      </View>
                    </>
                  )}
                </View>
              </Dropdown.Content>
            </Dropdown>
          </View.Item>
          <ActionDropdownMenu>
            <View padding={[1, 0]}>
              <View.Item>
                <View padding={[3, 4]}>
                  <Actionable fullWidth onClick={() => navigate(`${rootPath}/${ROUTES.SUBZONES}`)}>
                    <Text>{t('CycleCount.ManageAssignees')}</Text>
                  </Actionable>
                </View>
              </View.Item>
              <View.Item>
                <View padding={[3, 4]}>
                  <Actionable
                    fullWidth
                    onClick={() => setModalOpen(true)}
                    disabled={isCycleCountComplete}
                  >
                    <Text color={isCycleCountComplete ? 'gray-500' : 'error'}>
                      {t('CycleCount.EndCount')}
                    </Text>
                  </Actionable>
                </View>
              </View.Item>
            </View>
          </ActionDropdownMenu>
        </View>
      </MasterTitle>
      {isModalOpen && headerData && taskId && (
        <EndCountModal
          progressValue={progressValue || 0}
          isOpen={isModalOpen}
          countTypeCdValue={countTypeCd}
          taskId={taskId}
          week={week}
          year={headerData[0]?.year || Number('')}
          phase={headerData[0]?.phase || Number('')}
          onClose={() => setModalOpen(false)}
        />
      )}
    </>
  );
};
