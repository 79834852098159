/**
 * Copyright 2025 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

// Utility function to calculate total locations
export const calculateLocations = (
  totalFpsLayoutCountExpected: number | null | undefined,
  totalRsrvLayoutCountExpected: number | null | undefined
) => {
  let fpsLayoutCount = 0;
  let rsrvLayoutCount = 0;

  if (totalFpsLayoutCountExpected && totalFpsLayoutCountExpected !== null) {
    fpsLayoutCount = Number(totalFpsLayoutCountExpected);
  }

  if (totalRsrvLayoutCountExpected && totalRsrvLayoutCountExpected !== null) {
    rsrvLayoutCount = Number(totalRsrvLayoutCountExpected);
  }
  return fpsLayoutCount + rsrvLayoutCount;
};
