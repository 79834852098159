/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

export const PAST_COUNT_MAP_COLUMN = {
  WALL_TO_WALL: 'WALL_TO_WALL',
  COUNT_NAME: 'countName',
  STARTED_DATE: 'startedDate',
  CLOSED_DATE: 'closedDate',
  NET_ACCURACY: 'netAccuracy',
  TOTAL_COUNT: 'totalCount',
  NET_ADJUSTMENT: 'netAdjustment',
  ABS_PC_PER: 'absPcPer',
  TOTAL_PC_COUNT: 'totalPcCount',
  ABS_PC_ADJUSTMENT: 'absPcAdjustment',
  PROGRESS: 'progress',
  END_COUNT_REASON: 'endCountReason',
  ABS_PC_ACCURACY: 'absPcAccuracy',
};

export const PAST_COUNT_SORT_COLUMN = {
  PHASE: 'phase',
  START_TS: 'startTs',
  END_TS: 'endTs',
  TOTAL_AMOUNT_COUNTED: 'totalInvAmtCounted',
  TOTAL_INV_AMT_COUNTED: 'totalInvAmtCounted',
  TOTAL_INV_AMT_ADJ_CALC: 'totalInvAmtAdjCalc',
  TOTAL_INV_QTY_COUNTED: 'totalInvQtyCounted',
  TOTAL_INV_QTY_ADJUSTED_CALC: 'totalInvQtyAdjustedCalc',
  END_REASON: 'endReason',
  TOTAL_INV_AMT_ACCURACY: 'totalInvAmtAccuracy',
  TOTAL_INV_QTY_ACCURACY: 'totalInvQtyAccuracy',
};
